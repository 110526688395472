import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

import Layout from '../../components/Layout';
import Logo from '../../components/Logo';

import '../index.css';

export const query = graphql`
  query {
    # Hero Images
    heroDefaultLg: file(relativePath: { eq: "heros/default.jpg" }) {
      data: childImageSharp {
        fixed(width: 800, jpegProgressive: true, quality: 60) {
          src
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => {
  return (
    <Layout pageTitle="Thank you">
      <Helmet>
        <meta name="robota" content="noindex, nofollow" />
      </Helmet>
      <div
        className="bg-black items-center bg-cover h-screen"
        style={{ backgroundImage: `url(${data.heroDefaultLg.data.fixed.src})` }}
      >
        <div className="container mx-auto pt-8 md:flex text-center md:text-left">
          <div className="flex-1 ml-2 mb-4 md:mb-0 md:ml-0">
            <Logo />
          </div>
          <div className="mr-2 md:mr-0">
            <a
              href="https://www.themakersmob.com/login"
              className="text-grey mr-4 text-sm opacity-75 hover:opacity-100"
              target="_blank"
              rel="noopener noreferrer"
            >
              Already a member? Login here
            </a>
          </div>
        </div>
        <div className="flex">
          <div className="container text-center mx-auto">
            <div className="md:mx-0 my-24 md:mt-48 md:mb-48">
              <h1 className="mb-2 text-5xl text-grey-lighter">
                Thank You for Your Purchase
              </h1>
              <h2 className="text-md text-grey font-normal antialiased leading-normal mb-24">
                Please check your email for a message from the Makers Mob.{' '}
                <br />
                We'll see you on the inside.
              </h2>

              <div className="text-grey mb-16">
                If you've already receive your welcome email and completed the
                registration,{' '}
                <a
                  href="https://www.themakersmob.com/login"
                  className="text-grey mr-4 text-sm opacity-75 hover:opacity-100"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  please login here.
                </a>
              </div>
              <div className="text-grey">
                If you did not receive the welcome email, please check your SPAM
                folder and email{' '}
                <a
                  href="mailto:support@themakersmob.com"
                  className="text-grey mr-4 text-sm opacity-75 hover:opacity-100"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  support@themakersmob.com
                </a>
              </div>

              <div className="mt-12" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
